import parseTailwindBreakpoint from '@utils/parseTailwindBreakpoint';

const BREAKPOINT = parseTailwindBreakpoint();

const refreshCardCulture = (previousNav = null) => {
  if (previousNav) {
    document
      .querySelector(`#${previousNav.dataset.id}`)
      ?.classList.add('lg:hidden');
  }

  let selectedCulture = document.querySelector(
    '.Culture-content-nav-item.selected'
  );

  if (!selectedCulture) {
    const navs = document.querySelectorAll('.Culture-content-nav-item');
    navs[0].classList.add('selected');

    selectedCulture = document.querySelector(
      '.Culture-content-nav-item.selected'
    );
  }

  const selectedCards = document.querySelector(
    `#${selectedCulture.dataset.id}`
  );

  if (!selectedCards) {
    return;
  }

  const elementToHydrate = document.querySelector('.js-cultureHydrate');

  if (elementToHydrate) {
    elementToHydrate.innerHTML = selectedCards.innerHTML;
  }
};

const initNav = (isMobile = true) => {
  const navItems = document.querySelectorAll('.Culture-content-nav-item');

  navItems.forEach((navItem) => {
    if (isMobile) {
      const contentDisplay = document.querySelector(
        `#display-${navItem.dataset.id}`
      );
      const contentCard = document.querySelector(`#${navItem.dataset.id}`);

      if (contentCard && contentDisplay) {
        contentDisplay.appendChild(contentCard);
      }
    }

    navItem.addEventListener('click', ({ currentTarget }) => {
      const previousNavSelected = document.querySelector(
        '.Culture-content-nav-item.selected'
      );

      if (!isMobile) {
        if (
          previousNavSelected &&
          previousNavSelected.dataset.id === currentTarget.dataset.id
        ) {
          return;
        }

        if (previousNavSelected) {
          previousNavSelected.classList.remove('selected');
        }
        currentTarget.classList.add('selected');

        refreshCardCulture(previousNavSelected);
        initCards(isMobile);
      } else {
        const contentCard = document.querySelector(
          `#${currentTarget.dataset.id}`
        );

        if (!contentCard) {
          return;
        }

        contentCard.classList.toggle('open');
      }
    });
  });
};

const initCards = (isMobile = true) => {
  const cardsItems = document.querySelectorAll('.Culture-content-cards-item');

  cardsItems.forEach((cardItem) => {
    cardItem.addEventListener('click', (event) => {
      const { currentTarget } = event;

      if (currentTarget.localName === 'a') return;

      if (isMobile) {
        event.preventDefault();
        event.stopPropagation();

        window.location.href = currentTarget.dataset.url;
      } else {
        const SELECTED_CLASS = 'selected';
        const NOT_SELECTED_CLASS = 'notSelected';
        const { parentElement } = currentTarget;
        const selected = document.querySelector(
          `.Culture-content-cards-item.${SELECTED_CLASS}`
        );

        function selectCurrentCard() {
          currentTarget.classList.add(SELECTED_CLASS);
          parentElement
            .querySelectorAll(
              `.Culture-content-cards-item:not(.${SELECTED_CLASS})`
            )
            .forEach((elem) => {
              elem.classList.add(NOT_SELECTED_CLASS);
            });
        }

        parentElement
          .querySelectorAll(`.Culture-content-cards-item.${NOT_SELECTED_CLASS}`)
          .forEach((elem) => {
            elem.classList.remove(NOT_SELECTED_CLASS);
          });

        if (!selected) {
          selectCurrentCard();
        } else {
          selected.classList.remove(SELECTED_CLASS);

          if (selected !== currentTarget) {
            selectCurrentCard();
          }
        }
      }
    });
  });
};

const Culture = () => {
  let isMobile = window.innerWidth < BREAKPOINT.md;

  !isMobile && refreshCardCulture();

  initNav(isMobile);

  initCards(isMobile);
};

export default Culture;
